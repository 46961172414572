<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title mt-auto mb-auto mr-auto">
                        {{ $t('Wallet.Title') }}
                    </h2> 
                </b-col>
                <b-col v-if="dataWallet" cols="12" md="6" lg="4" xl="4" class="ml-auto">
                    <card style="background-color: #5D6176; border-radius: 10px;">
                        <b-row style="color: white; margin-left: 10px;">
                            <b-col>
                                <h5 class="m-auto"><b>{{ $t('Wallet.WalletBalance') }}</b></h5>
                            </b-col>
                            <b-col>
                                <h5 class="m-auto" id="walletBalance" v-if="dataWallet.balance"><b-icon icon="coin" class="mr-2"></b-icon>{{dataWallet.balance.toFixed(2)}}</h5>
                                <h5 class="m-auto" id="walletBalance" v-else><b-icon icon="coin" class="mr-2"></b-icon>0</h5>
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <card>
                        <b-row>
                            <b-col>
                                <card plain>
                                    <b-row>
                                        <b-col class="button-add-balance" v-if="$roleSelected.user_role === 'admin'">
                                            <b-row>
                                                <b-col class="d-flex">
                                                    <b-button style="background-color: white; font-size: 30px; margin: auto;" @click="showModalAdd()">
                                                        <b-icon icon="plus-circle" style="color: #5D6176"></b-icon>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col class="d-flex">
                                                    <span class="m-auto" id="addBalanceText">{{ $t('Wallet.AddBalance') }}</span>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="button-wallet-transfer"
                                            v-if="$roleSelected.user_role === 'admin'">
                                            <b-row>
                                                <b-col class="d-flex">
                                                    <b-button style="background-color: white; font-size: 30px; margin: auto;" @click="showModalTransfer()">
                                                        <b-icon icon="box-arrow-up-right" style="color: #5D6176;"></b-icon>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col class="d-flex" >
                                                    <span class="m-auto" id="walletTransferText">{{ $t('Wallet.WalletTransfer') }}</span>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="button-download-report">
                                            <b-row>
                                                <b-col class="d-flex">
                                                    <b-button style="background-color: white; font-size: 30px; margin: auto;" @click="downloadCSV">
                                                        <b-icon icon="download" style="color: #5D6176;"></b-icon>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col class="d-flex">
                                                    <span class="m-auto" id="downloadReportText">{{ $t('Wallet.DownloadReport') }}</span>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <!-- <b-col class="button-wallet-withdrawal-request">
                                            <b-row style="margin-left: 20px;">
                                                <b-button style="background-color: white; font-size: 50px;">
                                                    <b-icon icon="box-arrow-down" style="color: #5D6176;"></b-icon>
                                                </b-button>
                                            </b-row>
                                            <b-row>
                                                Wallet Withdrawal Request
                                            </b-row>
                                        </b-col> -->
                                    </b-row>
                                </card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-card style="background-color: #e7e7e7; border-radius: 75px; margin: 10px;">
                                <b-row class="flex-wrap">
                                    <b-col cols="12" md="2" class="d-flex">
                                        <b-form-group style="min-width: 100%;">
                                            <b-form-select v-model="filterWallet" class="filter-item"
                                                style="margin-top: 15px;" id="filterWallet">
                                                <b-form-select-option value="null">{{ $t("ExternalOrganizations.All")
                                                    }}</b-form-select-option>
                                                <b-form-select-option value="project">{{ $t('Wallet.ProjectsOrReports')
                                                    }}</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="2" class="d-flex" id="filterMethod">
                                        <b-form-select v-model="filterByMethod" class="filter-item">
                                            <b-form-select-option value="null">{{ $t('Wallet.FilterMethod')
                                                }}</b-form-select-option>
                                            <b-form-select-option value="reload">{{ $t('Wallet.Reload')
                                                }}</b-form-select-option>
                                            <b-form-select-option value="distribute">{{ $t('Wallet.Distribute')
                                                }}</b-form-select-option>
                                            <b-form-select-option value="sharing">{{ $t('Wallet.Sharing')
                                                }}</b-form-select-option>
                                            <b-form-select-option value="expend">{{ $t('Wallet.Expend')
                                                }}</b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col cols="12" md="2" class="d-flex" id="filterResponsible">
                                        <b-form-select v-model="filterByResponsible" class="filter-item">
                                            <b-form-select-option :value="null">{{ $t('Wallet.All')
                                                }}</b-form-select-option>
                                            <b-form-select-option value="external">{{ $t('Wallet.External')
                                                }}</b-form-select-option>
                                            <b-form-select-option value="staff">{{ $t('Wallet.Staff')
                                                }}</b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col cols="12" md="2">
                                        <b-row class="mt-3">
                                            <b-col>
                                                <b-form-input id="range-1" v-model="filterTokens" type="range" min="0"
                                                    max="2000" class="filter-item" />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="d-flex">
                                                <span class="m-auto" style="font-size: 13px;">> <b-icon
                                                        icon="coin"></b-icon> {{ filterTokens }}</span>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="12" md="2" class="d-flex">
                                        <input type="date" class="form-control filter-item"
                                            style="background: white; height: 45px; margin: auto;" id="startDate"
                                            v-model="startDate" />
                                    </b-col>
                                    <b-col cols="12" md="2" class="d-flex">
                                        <input type="date" class="form-control filter-item"
                                            style="background: white; height: 45px; margin: auto;" id="endDate"
                                            v-model="endDate" />
                                    </b-col>
                                </b-row>
                                <b-row v-if="filterByResponsible == 'external'">
                                    <b-col cols="4"></b-col>
                                    <b-col cols="12" md="2">
                                        <b-form-select v-model="filterByResponsibleName" class="filter-item">
                                            <b-form-select-option :value="null">{{ $t('Wallet.NoneFilter')
                                                }}</b-form-select-option>
                                            <b-form-select-option v-for="user in members" :key="user.id"
                                                :value="user.id">
                                                {{ user.name }} ({{ user.email }})
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-row>
                        <b-row>
                        <b-col>
                            <b-table
                            style=" min-height:400px; max-height: 400px; overflow-x: auto;"
                            ref="projectTable"
                            id="projectTable"
                            :fields="headersWallet" 
                            :items="filteredByResponsibleName"
                            responsive="sm"
                            show-empty
                            sort-by="created_at"
                            :sort-desc="true"
                            > 
                            <template #empty="">
                                {{$t('Wallet.Empty')}}
                            </template>
                            <template #cell(amount)="data">
                                <p :class="data.item.amount > 0 ? 'positive-color' : 'negative-color'">${{ data.item.amount }}</p>
                            </template>
                            <template #cell(token_value)="data">
                                <b-icon icon="coin"></b-icon> {{ data.item.token_value}}
                            </template>
                            <template #cell(target_name)="data">
                                <template v-if="data.item.report_id">
                                    {{ data.item.report.name }} ({{ data.item.report.project.name }})
                                </template>
                                <template v-else-if="data.item.project_id">
                                    {{ data.item.project.name }}
                                </template>
                                <template v-else-if="data.item.layer_id">
                                    {{ data.item.layer.internal_name }}
                                </template>
                                <template v-else-if="data.item.user_id">
                                    {{ data.item.user.email }}
                                </template>
                                <template v-else-if="data.item.description === 'User quota renewal'">
                                    {{ $t('RenewalQuoteToAdmin') }}
                                </template>
                                <template v-else-if="data.item.description === 'User quota renewal to other user'">
                                    {{ $t('RenewalQuoteToAdmin2') }}
                                </template>
                                <template v-else>
                                    {{ data.item.organization.name }}
                                </template>
                            </template>

                                    <template #cell(responsible)="data">
                                        <template v-if="data.item.staff_user_id">
                                            {{ data.item.staff_user.name }} ({{ data.item.staff_user.email }})
                                        </template>
                                        <template v-else-if="data.item.external_user_id">
                                            {{ data.item.external_user.name }} ({{ data.item.external_user.email }})
                                        </template>
                                    </template>
                                    <template #cell(date)="data">
                                        {{ data.item.created_at | formatDate }}
                                    </template>
                                </b-table>
                                <!-- <div style="display: flex;">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalPages"
                                    :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select" style="min-width: 100px !important">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option :value="dataWallet.length">All</b-form-select-option>
                                </b-form-select>
                            </div> -->

                                <!-- <b-table v-if="dataWallet && $roleSelected.user_role === 'project_manager'"
                            style=" min-height:400px; max-height: 400px; overflow-x: auto;"
                            ref="projectTable"
                            id="projectTable"
                            :fields="headersWallet" 
                            :items="filteredByRoleAndUser"
                            responsive="sm"
                            show-empty
                            sort-by="created_at"
                            :sort-desc="true"
                            > 
                            <template #empty="">
                                {{$t('Wallet.Empty')}}
                            </template>
                            <template #cell(amount)="data">
                                <p :class="data.item.amount > 0 ? 'positive-color' : 'negative-color'">${{ data.item.amount }}</p>
                            </template>


                            <template #cell(target_name)="data">
                                <template v-if="data.item.project_id">
                                    {{ data.item.project.name }}
                                </template>
                                <template v-else-if="data.item.report_id">
                                    {{ data.item.report.name }}
                                </template>
                                <template v-else-if="data.item.layer_id">
                                    {{ data.item.layer.internal_name }}
                                </template>
                                <template v-else-if="data.item.user_id">
                                    {{ data.item.user.email }}
                                </template>
                                <template v-else>
                                    {{ data.item.organization.name }}
                                </template>
                            </template>

                            <template #cell(responsible)="data">
                                <template v-if="data.item.staff_user_id">
                                    {{ data.item.staff_user.name }} ({{ data.item.staff_user.email }})
                                </template>
                                <template v-else-if="data.item.external_user_id">
                                    {{ data.item.external_user.name  }} ({{ data.item.external_user.email }})
                                </template>
                            </template>
                            <template #cell(date)="data">
                                {{ data.item.created_at | formatDate }}
                            </template>
                            </b-table>

                            <b-table v-if="dataWallet && $roleSelected.user_role === 'analyst'"
                            style=" min-height:400px; max-height: 400px; overflow-x: auto;"
                            ref="projectTable"
                            id="projectTable"
                            :fields="headersWallet" 
                            :items="filteredByRoleAndUser"
                            responsive="sm"
                            show-empty
                            sort-by="created_at"
                            :sort-desc="true"
                            > 
                            <template #empty="">
                                {{$t('Wallet.Empty')}}
                            </template>
                            <template #cell(amount)="data">
                                <p :class="data.item.amount > 0 ? 'positive-color' : 'negative-color'">${{ data.item.amount }}</p>
                            </template>


                            <template #cell(target_name)="data">
                                <template v-if="data.item.project_id">
                                    {{ data.item.project.name }}
                                </template>
                                <template v-else-if="data.item.report_id">
                                    {{ data.item.report.name }}
                                </template>
                                <template v-else-if="data.item.layer_id">
                                    {{ data.item.layer.internal_name }}
                                </template>
                                <template v-else-if="data.item.user_id">
                                    {{ data.item.user.email }}
                                </template>
                                <template v-else>
                                    {{ data.item.organization.name }}
                                </template>
                            </template>

                            <template #cell(responsible)="data">
                                <template v-if="data.item.staff_user_id">
                                    {{ data.item.staff_user.name }} ({{ data.item.staff_user.email }})
                                </template>
                                <template v-else-if="data.item.external_user_id">
                                    {{ data.item.external_user.name  }} ({{ data.item.external_user.email }})
                                </template>
                            </template>
                            <template #cell(date)="data">
                                {{ data.item.created_at | formatDate }}
                            </template>
                            </b-table> -->
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </b-container>
        <modal :show.sync="modals.add" header-classes="justify-content-center">
            <h4 slot="header" class="title title-up">
                {{ $t('Wallet.AddTokens') }}
            </h4>
            <card style="background-color: #5D6176; border-radius: 10px;">
                <b-row style="color: rgb(255, 255, 255);  font-weight: 600;">
                    <b-col>
                        <h4 class="m-0" style="font-weight:500">{{$t('ExchangeValue')}}:  1€ → <span style=" font-weight: 400; color: #c5c5c5;"> {{ value_tokens }} <b-icon icon="coin"></b-icon></span></h4> 
                    </b-col>
                </b-row>
            </card>
            <b-form-group label=" Tokens *" label-for="input-email_inv">
                <b-form-input
                    v-model="tokens_to_add"
                    id="input-tokens_to_add"
                    name="input-tokens_to_add"
                    v-validate="{required: true}"
                    :state="validateState('input-tokens_to_add')"
                    aria-describedby="input-tokens_to_add-live-feedback"
                    size="sm"
                    placeholder=" Tokens"
                ></b-form-input>
                <b-form-invalid-feedback id="input-tokens_to_add-live-feedback">{{ veeErrors.first('input-tokens_to_add') }}</b-form-invalid-feedback>
            </b-form-group>
            <n-button class="btn-simple ncm-btn" @click.native="submit()">
                {{ $t('UsersManagement.Modals.Invite.Send') }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.add = false">
                {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }}
            </n-button>
        </modal>
        <modal :show.sync="modals.payment" header-classes="justify-content-center">
            <h4 slot="header" class="title title-up">
                {{ $t('Wallet.AddTokens') }}
            </h4>
            <card style="background-color: #5D6176; border-radius: 10px;">
                <b-row style="color: rgb(255, 255, 255);  font-weight: 600;">
                    <b-col>
                        <h4 class="m-0" style="font-weight:500">{{$t('YouPay')}}: {{ value_to_pay }}€  <span style=" font-weight: 400; color: #c5c5c5;"> {{ $t('For') }} → {{ tokens_to_add }} <b-icon icon="coin"></b-icon></span></h4> 
                    </b-col>
                </b-row>
            </card>
            <form id="payment-form">
                <div id="payment-element">
                    <!--Stripe.js injects the Payment Element-->
                </div>
            </form>
            <n-button class="btn-simple ncm-btn" @click.native="pay()">
                {{ $t('UsersManagement.Modals.Invite.Send') }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.payment = false">
                {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }}
            </n-button>
        </modal>
        <modal :show.sync="modals.transfer" header-classes="justify-content-center">
            <h4 slot="header" class="title title-up">
                {{ $t('Wallet.TransferTokens') }}
            </h4>
            <card style="background-color: #5D6176; border-radius: 10px;">
                <b-row style="color: white; font-weight: 600;">
                    <b-col>
                        {{ $t('Wallet.WalletBalance') }}
                    </b-col>
                </b-row>
                <b-row style="color: rgb(255, 255, 255);  font-weight: 600;">
                    <b-col>
                        <h2 v-if ="!tokens_to_transfer" class="m-0" style="font-weight:500"><b-icon icon="coin"></b-icon>{{dataWallet.balance.toFixed(2)}}  → </h2>
                        <h2 class="m-0" v-else style="font-weight:500"><b-icon icon="coin"></b-icon>{{dataWallet.balance.toFixed(2)}}  → <span style=" font-weight: 400; color: #c5c5c5;"><b-icon icon="coin"></b-icon>{{ computedTokensToTransfer.toFixed(2)}}</span> </h2>
                    </b-col>
                </b-row>
            </card>
            <b-row>
                <b-col>
                    <b-form-group label="Type *" label-for="input-email_inv">
                        <b-form-select v-model="type_transfer" style="font-size: 13px;">
                            <b-form-select-option value="null">{{ $t('Wallet.SelectType') }}</b-form-select-option>
                            <b-form-select-option value="distribute">{{ $t('Wallet.Distribute')
                                }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <template v-if="type_transfer === 'distribute'">
                    <b-col>
                        <b-form-group label="To *" label-for="input-email_inv">
                            <b-form-select v-model="distribute_to" style="font-size: 13px;">
                                <b-form-select-option value="null">{{ $t('Wallet.SelectDestination')
                                    }}</b-form-select-option>
                                <b-form-select-option value="project">{{ $t('Wallet.Project') }}</b-form-select-option>
                                <!-- <b-form-select-option value="user">User</b-form-select-option> -->
                                <b-form-select-option value="organization">{{ $t('Wallet.Organization')
                                    }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </template>
            </b-row>
            <b-row v-if="type_transfer === 'distribute' && distribute_to === 'user'">
                <b-col>
                    <b-form-group label="User *" label-for="input-email_inv">
                        <b-form-select v-model="to_transfer" style="font-size: 13px;">
                            <b-form-select-option value="null">{{ $t('Wallet.SelectUser') }}</b-form-select-option>
                            <b-form-select-option v-for="user in members" :key="user" :value="user.id">
                                {{ user.name }} ({{ user.email }})
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="type_transfer === 'distribute' && distribute_to === 'project'">
                <b-col>
                    <b-form-group label="Project *" label-for="input-email_inv">
                        <b-form-select v-model="to_transfer" style="font-size: 13px;">
                            <b-form-select-option value="null">{{ $t('Wallet.SelectProject') }}</b-form-select-option>
                            <b-form-select-option v-for="pj in projects" :key="pj" :value="pj.id">
                                {{ pj.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="type_transfer === 'distribute' && distribute_to === 'organization'">
                <b-col>
                    <b-form-group label="Organization *" label-for="input-email_inv">
                        <b-form-select v-model="to_transfer" style="font-size: 13px;">
                            <b-form-select-option value="null">{{ $t('Wallet.SelectOrganization')
                                }}</b-form-select-option>
                            <b-form-select-option v-for="org in orgs" :key="org" :value="org.id">
                                {{ org.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label=" Tokens *" label-for="input-email_inv">
                <b-form-input v-model="tokens_to_transfer" id="input-email_inv" name="input-email_inv"
                    v-validate="{ required: true }" :state="validateState('input-email_inv')"
                    aria-describedby="input-email_inv-live-feedback" size="sm" placeholder="Tokens"></b-form-input>
                <b-form-invalid-feedback id="input-email_inv-live-feedback">{{ veeErrors.first('input-email_inv')
                    }}</b-form-invalid-feedback>
            </b-form-group>
            <n-button class="btn-simple ncm-btn" @click.native="transferTokens()">
                {{ $t('UsersManagement.Modals.Invite.Send') }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.transfer = false">
                {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }}
            </n-button>
        </modal>
    </div>
</template>
<script>
import StaffServices from '../../services/StaffServices';
import { Badge, Button, InfoSection, Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown } from '@/components';
import InvitatonDataServices from '../../services/InvitationDataService'
import { Table, TableColumn, Tooltip } from 'element-ui';
import moment from 'moment'
import WalletDataServices from '../../services/WalletDataServices';
import OrganizationsDataService from '../../services/OrganizationsDataService';
import ProjectsDataService from '../../services/ProjectsDataService'
import Papa from "papaparse";
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

const stripe = Stripe(process.env.VUE_APP_VITE_STRIPE_PK);

export default {
    components: {
        Card,
        Modal,
        [Checkbox.name]: Checkbox,
        [DropDown.name]: DropDown,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
        StripeCheckout,
        StripeElementPayment,
        StripeElementCard,
    },
    data() {
        return {
            modals: {
                add: false,
                transfer: false,
                payment: false
            },
            projects: [],
            type_transfer: null,
            to_transfer: null,
            tokens_to_transfer: null,
            distribute_to: null,
            currentPage: 1,
            perPage: 10,
            filterByResponsible: null,
            filterByMethod: null,
            filterWallet: null,
            filterTokens: 0,
            filterByResponsibleName: null,
            value_tokens: process.env.VUE_APP_VITE_VALUE_TOKENS,
            startDate: null,
            endDate: null,
            members: [],
            visibilityFilter: null,
            wallet: {},
            filter: {
                user_id: null
            },
            wallets: [
                { num: '1', date: new Date(), responsible: 'NCM', transactionId: '167887575611', amount: 5.00, description: 'Wallet credited through visiting site', target: 'organization', method: 'Site visit' },
                { num: '2', date: new Date(), responsible: 'Analyst', transactionId: '167835646910', amount: 5.00, description: 'Wallet credited through visiting site', target: 'organization', method: 'Site visit' },
                { num: '3', date: new Date(), responsible: 'NCM', transactionId: '16778344619', amount: 5.00, description: 'Wallet credited through visiting site', target: 'report', method: 'Site visit' },
                { num: '4', date: new Date(), responsible: 'Analyst', transactionId: '16776772028', amount: 5.00, description: 'Wallet credited through visiting site', target: 'project', method: 'Site visit' },
                { num: '5', date: new Date(), responsible: 'PM', transactionId: '16775816617', amount: 5.00, description: 'Wallet credited through #4', target: 'organization', method: 'Product review' },
                { num: '6', date: new Date(), responsible: 'NCM', transactionId: '16775663306', amount: -40.00, description: 'Wallet debited through user', target: 'project', method: 'Manually By Admin' },
            ],
            inv: [],
            projects: [],
            orgs: [],
            org_id: null,
            invitations: [],
            tokens_to_add: null,
            filteredArrayInvitations: [],
            headersWallet: [
                // { key: 'id', label: this.$t('Wallet.Number'), sortable: true },
                { key: 'organization.name', label: this.$t('Wallet.OrganizationName') },
                { key: 'type', label: this.$t('Wallet.Method'), sortable: true },
                { key: 'level', label: this.$t('Wallet.Target') },
                { key: 'token_value', label: this.$t('Wallet.Amount') },
                { key: 'target_name', label: this.$t('Wallet.TargetName'), sortable: true },
                { key: 'responsible', label: this.$t('Wallet.Responsible'), sortable: true },
                // { key: 'report_name', label: 'Report name' },
                { key: 'date', label: this.$t('Wallet.CreatedAt'), sortable: true },
            ],
            balance: 0,
            wallet_movements: [],
            dataWallet: [],
            loading: false,
            lineItems: [],
            successURL: 'http://212.128.156.140/wallet',
            cancelURL: 'http://212.128.156.140/wallet',
            elementsOptions: {
                appearance: {}, // appearance options
            },
            confirmParams: {
                return_url: 'http://212.128.156.140/wallet?success=true', // success url
            },
            stripe_token: 0,
            paymentIntentCreated: false,
            showCard: false,
            elements: undefined,
            value_to_pay: 0,
        }
    },
    computed: {
        filteredWalletByTarget() {
            let dataWallet = this.dataWallet.wallet_movements
            if (this.filterWallet == 'project') {
                return dataWallet.filter(transaction => transaction.level === "project" || transaction.level == 'report');
            }
            return dataWallet
        },
        // filteredWalletByResponsible() {
        //     let dataWallet = this.filteredWalletByTarget
        //     if (this.filterByResponsible == null) return dataWallet
        //     if (this.filterByResponsible == '') return dataWallet
        // },
        computedTokensToTransfer() {
            return this.dataWallet.balance - this.tokens_to_transfer
        },
        filteredByMethod() {
            let dataWallet = this.filteredWalletByTarget
            if (this.filterByMethod == 'reload') {
                return dataWallet.filter(transaction => transaction.type === "reload");
            }
            if (this.filterByMethod == 'distribute') {
                return dataWallet.filter(transaction => transaction.type === "distribute");
            }
            if (this.filterByMethod == 'sharing') {
                return dataWallet.filter(transaction => transaction.type === "sharing");
            }
            if (this.filterByMethod == 'expend') {
                return dataWallet.filter(transaction => transaction.type === "expend");
            }
            return dataWallet
        },
        filteredByTokens() {
            let dataWallet = this.filteredByMethod
            if (this.filterTokens != 0) {
                return dataWallet.filter(transaction => transaction.token_value > this.filterTokens);
            }
            return dataWallet
        },
        filteredByDate() {
            let dataWallet = this.filteredByTokens
            if (this.startDate != null && this.endDate != null) {
                return dataWallet.filter(transaction => transaction.created_at >= this.startDate && transaction.created_at <= this.endDate);
            }
            return dataWallet
        },
        filteredByResponsible() {
            let dataWallet = this.filteredByDate
            if (this.filterByResponsible != null) {
                if (this.filterByResponsible === "staff") {
                    return dataWallet.filter(transaction => transaction.staff_user_id !== null);
                }
                if (this.filterByResponsible === "external") {
                    return dataWallet.filter(transaction => transaction.staff_user_id === null);
                }
            }
            return dataWallet;
        },
        filteredByRole() {
            let dataWallet = this.filteredByResponsible
            // if(this.$roleSelected.user_role === 'admin'){
            //     return dataWallet
            // }
            if (this.$roleSelected.user_role === 'project_manager') {
                return dataWallet.filter(transaction => transaction.staff_user_id === this.$roleSelected.id)
            }
            if (this.$roleSelected.user_role === 'analyst') {
                return dataWallet.filter(transaction => transaction.external_user_id === this.$roleSelected.id)
            }
        },
        filteredByResponsibleName() {
            let dataWallet = this.filteredByResponsible
            if (this.filterByResponsibleName != null) {
                return dataWallet.filter(transaction => transaction.external_user_id == this.filterByResponsibleName);
            }
            return dataWallet
        },
        filteredByRoleAndUser() {
            let dataWallet = this.filteredByRole
            if (this.filterByResponsibleName != null) {
                return dataWallet.filter(transaction => transaction.external_user_id == this.filterByResponsibleName);
            }
            return dataWallet
        },
        totalPages() {
            return this.dataWallet.length
        },
        onUpdate() {
            this.$refs.userTable.refresh();
            this.$forceUpdate();
        },
    },
    watch: {
        $orgSelected(newValue) {
            this.org_id = newValue.id
            this.getData()
        },
        $roleSelected(newValue) {
            console.log('new role', newValue)
            this.getData()
        },
        $projectSelected(newValue) {
            this.getData()
        }
    },
    created() {
        console.log(process.env.VUE_APP_VITE_STRIPE_PK)
        console.log(this.$stripe)
        if (this.$route.query.payment_intent_client_secret) {
            this.confirmPayment()
        }
        this.getData()
        // this.generatePaymentIntent()
    },
    methods: {
        async confirmPayment(){
            let client_secret = this.$route.query.payment_intent_client_secret
            const { paymentIntent } = await stripe.retrievePaymentIntent(client_secret);
            console.log(paymentIntent)
            switch (paymentIntent.status) {
                case "succeeded":
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.TokensAdded'),
                        text: this.$t('Alerts.TokensAddedToBalance')
                    }).then(() => {
                        this.modals.add = false
                        
                    });
                break;
                case "processing":
                this.$swal.fire({
                        icon: 'success',
                        title: 'Your payment is processing',
                        text: 'Your payment is still in process, please reload this page to check your payment status'
                    }).then(() => {
                        this.modals.add = false
                        
                    });
                break;
                case "requires_payment_method":
                this.$swal.fire({
                        icon: 'error',
                        title: 'Your payment was not successful.',
                        text: 'Please try again.'
                    }).then(() => {
                        this.modals.add = false
                        
                    });
                break;
                default:
                this.$swal.fire({
                        icon: 'error',
                        title: 'Your payment was not successful',
                        text: 'Something went wrong'
                    }).then(() => {
                        this.modals.add = false
                        
                    });
                break;
            }
        },
        async pay () {

        let elements = this.elements
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: "http://212.128.156.140/wallet",
            },
            redirect: 'if_required'
        });

        console.log(paymentIntent)

        if (error) {
            console.error(error);
            // handleError();
        }
        else{
            switch (paymentIntent.status) {
                case "succeeded":
                    console.log('succeeded!', paymentIntent)
                    this.reloadTokens(paymentIntent.amount, paymentIntent.client_secret)
                    
                break;
                case "processing":
                this.$swal.fire({
                        icon: 'info',
                        title: 'Your payment is processing',
                        text: 'Your payment is still in process, please reload this page to check your payment status'
                    }).then(() => {
                        this.modals.payment = false
                        
                    });
                break;
                case "requires_payment_method":
                this.$swal.fire({
                        icon: 'error',
                        title: 'Your payment was not successful.',
                        text: 'Please try again.'
                    }).then(() => {
                        this.modals.payment = false
                        
                    });
                break;
                default:
                this.$swal.fire({
                        icon: 'error',
                        title: 'Your payment was not successful',
                        text: 'Something went wrong'
                    }).then(() => {
                        this.modals.payment = false
                        
                    });
                break;
            }
        }
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            showMessage(error.message);
        } else {
            showMessage("An unexpected error occurred.");
        }

        
        console.log(element)
        },
        // tokenCreated (token) {
        // console.log(token);
        // // handle the token
        // // send it to your server
        // this.stripe_token = token.id
        // let data = {
        //             organization_id: this.org_id,
        //             level: 'organization',
        //             token_value: this.tokens_to_add,
        //             stripe_token_uuid: token.id,
        //         }
        //         if(this.tokens_to_add == null || this.tokens_to_add < 0){
        //         this.$swal.fire({
        //             icon: 'error',
        //             title: 'ERROR',
        //             text: this.$t('Alerts.ErrorTokensNegative')
        //         })
        //         return
        //     }   
        // WalletDataServices.payment(data).then(
        //             Response => {
        //                 console.log(Response)
        //             },
        //             Error => {
        //                 this.$swal.fire({
        //                     icon: 'error',
        //                     title: 'Error',
        //                     text: Error.response.data.message
        //                 })
        //             }
        // )
        // },
        // async generatePaymentIntent () {
        //     console.log('payment intent')
        // const paymentIntent = await apiCallToGeneratePaymentIntent(); 
        // this.elementsOptions.clientSecret = paymentIntent.client_secret;
        // },
        submit () {
            var data = {
                amount: this.tokens_to_add * 100,
                currency: 'eur'
            }
            this.$validator.validateAll(['input-tokens_to_add']).then(result => {
                if (!result) {
                    return
                }
                
        
            this.value_to_pay = this.tokens_to_add * this.value_tokens
            WalletDataServices.paymentIntent(data).then(
                    Response => {
                        console.log(Response)
                        let clientSecret = Response.data.data.client_secret;
                        const appearance = {
                            theme: 'stripe',
                        };
                        console.log(clientSecret)
                        this.elements = stripe.elements({ appearance, clientSecret });

                        const paymentElementOptions = {
                            layout: "tabs",
                        };

                        const paymentElement = this.elements.create("payment", paymentElementOptions);
                        paymentElement.mount("#payment-element");
                        console.log(this.elements)
                        this.paymentIntentCreated = true
                        this.modals.add = false,
                        this.modals.payment = true
                        // this.$refs.paymentRef.submit();  
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                            }
                )
            })
        },
        

        
        // async checkout() {
        //     try {
        //         let data = {
        //             organization_id: this.org_id,
        //             level: 'organization',
        //             token_value: this.tokens_to_add,
        //             stripe_token_uuid: 'abcd',
        //         }
        //         if(this.tokens_to_add == null || this.tokens_to_add < 0){
        //             this.$swal.fire({
        //                 icon: 'error',
        //                 title: 'ERROR',
        //                 text: this.$t('Alerts.ErrorTokensNegative')
        //             })
        //             return
        //         }   
        //         console.log(data)
        //         var item = {
        //             price:  '50', // The id of the one-time price you created in your Stripe dashboard
        //             quantity: 1,
        //         }
        //         this.lineItems.push(item)
        //         this.$refs['checkoutRef'].redirectToCheckout();
        //         WalletDataServices.reloadTokens(data).then(
        //             Response => {
        //                 console.log(Response)
                        
        //                 // this.$swal.fire({
        //                 //             icon: 'success',
        //                 //             title: this.$t('Alerts.TokensAdded'),
        //                 //             text: this.$t('Alerts.TokensAddedToBalance')
        //                 //         }).then(() => {
        //                 //             this.modals.add = false
        //                 //             this.getData()
        //                 //         });

        //             },
        //             Error => {
        //                 this.$swal.fire({
        //                     icon: 'error',
        //                     title: 'Error',
        //                     text: Error.response.data.message
        //                 })
        //             }
        //         )
                
        //     } catch (error) {
        //     console.error('Error:', error);
        //     }
        // },
        onSuccess() {
            console.log('Payment successful!');
        },
        onError() {
            console.error('Payment failed!');
        },
        downloadCSV() {
            console.log(this.filteredByDate);
            var blob = new Blob([Papa.unparse(this.filteredByDate)], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement("a");

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'walletMovements.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        showModalAdd() {
            this.modals.add = true
        },
        showModalTransfer() {
            this.modals.transfer = true
        },
        applyRowClass(row) {
            if (row != null) {
                if (row.invitation.is_rejected) {
                    return 'disabled-row';
                } else {
                    return '';
                }
            }
        },
        async getData() {
            this.org_id = this.$orgSelected.id
            if (this.org_id != null) {
                if (this.$roleSelected.user_role == 'admin') {
                    WalletDataServices.getTokens(this.org_id, this.$roleSelected.user_role).then(
                        Response => {
                            this.dataWallet = Response.data
                            if (Response.data.balance) {
                                if (Response.data.balance > 0) {
                                    this.$tokensValue = Response.data.balance.toFixed(2)
                                }
                                else {
                                    this.$tokensValue = '0.00'
                                }
                            }

                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    )
                }
                else{
                    if (this.org_id != null && this.$roleSelected.user_role != null && this.$projectSelected.id != null) {
                        WalletDataServices.getTokens(this.org_id, this.$roleSelected.user_role, this.$projectSelected.id).then(
                            Response => {
                                this.dataWallet = Response.data
                            },
                            Error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: Error.response.data.message
                                })
                            }
                        )
                    }
                }


                OrganizationsDataService.findByOrganizationId(this.org_id).then(
                    Response => {
                        this.members = Response.data.data
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                })
                if (this.$roleSelected.user_role) {
                    ProjectsDataService.findProjectsByOrganizationId(this.org_id, this.$roleSelected.user_role).then(
                        Response => {
                            this.projects = Response.data.data   
                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    )
                }
                await OrganizationsDataService.getOrganizationsFromAUser(JSON.parse(localStorage.getItem("user")).data.id).then(
                    Response => {
                        this.orgs = Response.data.data
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
                )
            }
        },
        transferTokens() {
            let data = {
                tokens: this.tokens_to_transfer,
                level: this.distribute_to,
                id: this.to_transfer,
                organization_id: this.org_id,
                stripe_token_uuid: "a9234dka9"
            }
            if (this.tokens_to_transfer == null || this.tokens_to_transfer < 0) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: this.$t('Alerts.ErrorTokensNegative')
                })
                return
            }

            if (this.wallet.balance < this.tokens_to_transfer) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: this.$t('Alerts.ErrorNoTokensToTransfer')
                })
                return
            }
            console.log(data)
            WalletDataServices.distributeTokens(data).then(
                Response => {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.TokensTransferred'),
                    }).then(() => {
                        this.modals.transfer = false
                        this.type_transfer = null,
                            this.to_transfer = null,
                            this.tokens_to_transfer = null,
                            this.distribute_to = null,
                            this.getData()
                    });
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        reloadTokens(amount, client_secret) {
            console.log(amount)
            console.log(client_secret)
            let data = {
                organization_id: this.org_id,
                level: 'organization',
                token_value: amount,
                stripe_token_uuid: client_secret,
            }
            console.log(data)
            WalletDataServices.reloadTokens(data).then(
                Response => {
                    console.log(Response)
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.TokensAdded'),
                        text: this.$t('Alerts.TokensAddedToBalance')
                    }).then(() => {
                        this.modals.payment = false
                        this.getData()
                    });

                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        }
    }

}
</script>
<style scoped>
.disabled-row {
    background-color: #f8f8f8;
    color: #999999;
}

.positive-color {
    color: green;
}

.negative-color {
    color: red;
}

.filter-item {
    width: 100%;
    font-size: 13px;
}

#filterMethod {
    margin-top: 15px;
}

#filterResponsible {
    margin-top: 15px;
}

@media(max-width: 768px) {
    #filterMethod {
        margin-top: 0px;
    }

    /* #filterResponsible{
        margin-top: 0px;
    } */
    #filterWallet {
        /* width: auto; */
    }
}

@media(max-width: 409px) {
    .dataWalletBalance {
        font-size: 10px;
    }
}

@media (max-width: 498px) {
    #walletBalance {
        margin-top: 15px!important;
        font-size: 15px;
    }
}

@media (max-width: 540px) {
    #walletTransferText{
        text-align: center;
    }
}

@media (max-width: 380px) {
    #walletTransferText{
        text-align: center;
    }
}

@media (max-width: 345px) {
    #addBalanceText{
        text-align: center;
    }
}

@media (max-width: 602px) {
    #downloadReportText {
        text-align: center;
    }
}

@media (max-width: 488px) {
    #addBalanceText {
        text-align: center;
    }
}
</style>