import Vue from "vue";
import Router from "vue-router";
import Components from "./pages/Components.vue";
import Presentation from "./pages/Presentation.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import StarterNavBar from "./layout/StarterNavbar.vue"
import MainFooter from "./layout/MainFooter.vue";
import About from "./pages/example-pages/AboutUs.vue";
import BlogPost from "./pages/example-pages/BlogPost.vue";
import Explore from "./pages/example-pages/Explore.vue"
import Posts from "./pages/example-pages/Posts.vue"
import BlogPosts from "./pages/example-pages/BlogPosts.vue";
import ContactUs from "./pages/example-pages/ContactUs.vue";
import LandingPage from "./pages/example-pages/LandingPage.vue";
import LoginPage from "./pages/example-pages/LoginPage.vue";
import PricingPage from "./pages/example-pages/PricingPage.vue";
import EcommercePage from "./pages/example-pages/EcommercePage.vue";
import ProductPage from "./pages/example-pages/ProductPage.vue";
import ProfilePage from "./pages/example-pages/ProfilePage.vue";
import SignUpPage from "./pages/example-pages/SignUpPage.vue";
import Sections from "./pages/Sections.vue";
import Privacy from "./pages/example-pages/Privacy.vue"
import Cookies from "./pages/example-pages/Cookies.vue"
import DataProtection from "./pages/example-pages/DataProtection.vue"
import LoginStaff from "./pages/example-pages/LoginStaff.vue"
import LogStaffNavBar from "./layout/LogStaffNavBar.vue"
import LogExternalUserBar from "./layout/LogExternalUserBar"
import ResetPassStaff from "./pages/example-pages/ResetPassStaff.vue"
import DashboardStaff from "./pages/example-pages/DashboardStaff.vue"
import ActiveUser from "./pages/example-pages/ActiveUser.vue"
import ActiveExternalUser from "./pages/example-pages/ActiveExternalUser.vue"
import Administrators from "./pages/example-pages/Admins.vue"
import Ecosystems from "./pages/example-pages/Ecosystems.vue"
import EcosystemEdit from "./pages/example-pages/EcosystemEdit.vue"
import EcosystemServices from "./pages/example-pages/EcosystemServices.vue"
import EcosystemsServicesTypes from "./pages/example-pages/EcosystemsServicesTypes.vue"
import EcosystemsServicesCategories from "./pages/example-pages/EcosystemsServicesCategories.vue"
import EcosystemTypes from "./pages/example-pages/EcosystemTypes.vue"
import EcosystemCategories from "./pages/example-pages/EcosystemCategories.vue"
import Parameters from "./pages/example-pages/Parameters.vue"
import AddPost from "./pages/example-pages/AddPost.vue"
import Profile from "./pages/example-pages/Profile.vue"
import Settings from "./pages/example-pages/Settings.vue"
import Wallet from "./pages/example-pages/Wallet.vue"
import Languages from "./pages/example-pages/Languages.vue"
import EcosystemLanguageEdit from "./pages/example-pages/EcosystemLanguageEdit.vue"
import EcosystemServiceLanguageEdit from "./pages/example-pages/EcosystemServiceLanguageEdit.vue"
import ParameterLanguageEdit from "./pages/example-pages/ParameterLanguageEdit.vue"
import NavBarBlog from "./layout/NavBarBlog.vue"
import NavBarPosts from "./layout/NavBarPosts.vue"
import PostsLanding from "./pages/example-pages/PostsLanding.vue"
import TutorialsLanding from "./pages/example-pages/TutorialsLanding.vue"
import Advantages from "./pages/example-pages/Advantages.vue"
import CategoriesPosts from "./pages/example-pages/CategoriesPosts.vue"
import PostsEdit from "./pages/example-pages/PostsEdit.vue"
import Post from "./pages/example-pages/Post.vue"
import Tutorial from "./pages/example-pages/Tutorial.vue"
import Multimedia from "./pages/example-pages/Multimedia.vue"
import PostsMultimedia from "./pages/example-pages/PostsMultimedia.vue"
import CategoriesTutorials from "./pages/example-pages/CategoriesTutorials.vue"
import Tutorials from "./pages/example-pages/Tutorials.vue"
import TutorialEdit from "./pages/example-pages/TutorialEdit.vue"
import TutorialsMultimedia from "./pages/example-pages/TutorialsMultimedia.vue"
import AddTutorial from "./pages/example-pages/AddTutorial.vue"
import NavBarLogo from "./layout/NavBarLogo"
import FooterLogo from "./layout/FooterLogo"
import ExternalOrganizations from "./pages/example-pages/ExternalOrganizations"
import ExternalOrganizationsTransfers from "./pages/example-pages/ExternalOrganizationsTransfers"
import UserHistory from "./pages/example-pages/UserHistory"
import SettingsCookies from "./pages/example-pages/SettingsCookies"
import HomeUser from "./pages/example-pages/HomeUser"
import SignUpInvitation from "./pages/example-pages/SignUpInvitation"
import AcceptInvitation from "./pages/example-pages/AcceptInvitation"
import AcceptInvitationAnalyst from "./pages/example-pages/AcceptInvitationAnalyst"
import UsersManagement from "./pages/example-pages/UsersManagement"
import ProjectsManagement from "./pages/example-pages/ProjectsManagement"
import AddProject from "./pages/example-pages/AddProject"
import OrganizationSettings from "./pages/example-pages/OrganizationSettings"
import SettingsEmails from "./pages/example-pages/SettingsEmails"
import Maps from "./pages/example-pages/Maps"
import StaffLayers from "./pages/example-pages/StaffLayers"
import ExternalLayers from "./pages/example-pages/ExternalLayers"
import AdminPanelCadastre from "./pages/example-pages/AdminPanelCadastre"
import AdminPanelDataLayers from "./pages/example-pages/AdminPanelDataLayers"
import Invitations from "./pages/example-pages/Invitations"
import ViewOrg from "./pages/example-pages/ViewOrg"
import UsersManagementPM from "./pages/example-pages/UsersManagementPM"
import ExternalUsers from "./pages/example-pages/ExternalUsers.vue";
import ReportsCheck from "./pages/example-pages/ReportsCheck"
import Reports from "./pages/example-pages/Reports"
import ReportsDownload from "./pages/example-pages/ReportsDownload"
import PDFDesign from "./pages/example-pages/PDFDesign"
import NotFound from "./pages/example-pages/NotFound"
Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    { // LANDING PAGE, PRINCIPAL SITE
      path: "/",
      name: "landing",
      components: {default: LandingPage, header: MainNavbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: 450}}
    },
    { 
      path: "/privacy",
      name: "privacy",
      components: {default: Privacy, header: StarterNavBar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: 65}},
    },
    { 
      path: "/cookies",
      name: "cookies",
      components: {default: Cookies, header: StarterNavBar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: 65}}
    },
    { 
      path: "/data-policy",
      name: "data-policy",
      components: {default: DataProtection, header: StarterNavBar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: 65}}
    },
    { 
      path: "/staff",
      name: "staff",
      components: {default: LoginStaff, header: StarterNavBar},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    { 
      path: "/reset-password",
      name: "reset-password",
      components: {default: ResetPassStaff, header: StarterNavBar},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/active-user",
      name: "activeUser",
      components: {default: ActiveUser, header: NavBarLogo,footer: FooterLogo},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/active-external-user",
      name: "active-external-user",
      components: {default: ActiveExternalUser, header: NavBarLogo,footer: FooterLogo},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/explore",
      name: "explore",
      components: {default: Explore, header: NavBarBlog, footer: MainFooter},
      props: true,
    },
    {
      path: "/posts-landing",
      name: "PostsLanding",
      components: {default: PostsLanding, header: NavBarPosts, footer: MainFooter},
      props: true,
      beforeEnter : guardMyrouteLogin
    },
    {
      path: "/tutorials-landing",
      name: "TutorialsLanding",
      components: {default: TutorialsLanding, header: NavBarPosts, footer: MainFooter},
      props: {header: {colorOnScroll: 65}},
      beforeEnter : guardMyrouteLogin
    },
    {
      path: "/post/:category/:title_slug",
      name: "Post",
      components: {default: Post, header: NavBarPosts, footer: MainFooter},
      props: true,
      beforeEnter : guardMyrouteLogin
    },
    {
      path: "/tutorial/:category/:title_slug",
      name: "Tutorial",
      components: {default: Tutorial, header: NavBarPosts, footer: MainFooter},
      props: {header: {colorOnScroll: 65}},
      beforeEnter : guardMyrouteLogin
    },
    {
      path: "/advantages",
      name: "Advantages",
      components: {default: Advantages, header: NavBarPosts, footer: MainFooter},
      props: {header: {colorOnScroll: 65}}
    },
    {
      path: "/sign-up",
      name: "sign-up",
      components: {default: SignUpPage, header: NavBarLogo},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/signup-invite",
      name: "sign-up-invitation",
      components: {default: SignUpInvitation, header: NavBarLogo},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/accept-invitation",
      name: "sign-up-accept",
      components: {default: AcceptInvitation, header: NavBarLogo},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/accept-invitation-analyst",
      name: "accept-invitation-analyst",
      components: {default: AcceptInvitationAnalyst, header: NavBarLogo},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/report-check",
      name: "report-check",
      components: {default: ReportsCheck, header: NavBarLogo},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/report-download",
      name: "report-download",
      components: {default: ReportsDownload, header: NavBarLogo},
      props: {header: {colorOnScroll: 450}}
    },
    // {
    //   path: "/components",
    //   name: "components",
    //   components: {default: Components, header: MainNavbar, footer: MainFooter},
    //   props: {
    //     footer: {backgroundColor: 'black'},
    //     header: {colorOnScroll: 65}
    //   }
    // },
    // {
    //   path: "/presentation",
    //   name: "presentation",
    //   components: {default: Presentation, header: MainNavbar, footer: MainFooter},
    //   props: {header: {colorOnScroll: 500}}
    // },
    // {
    //   path: "/about",
    //   name: "about",
    //   components: {default: About, header: MainNavbar, footer: MainFooter},
    //   props: {header: {colorOnScroll: 450}}
    // },
    // {
    //   path: "/contact",
    //   name: "contact",
    //   components: {default: ContactUs, header: MainNavbar, footer: MainFooter},
    //   props: {footer: {type: 'default'}}
    // },
    {
      path: "/login",
      name: "login",
      components: {default: LoginPage, header: StarterNavBar},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    // {
    //   path: "/pricing",
    //   name: "pricing",
    //   components: {default: PricingPage, header: MainNavbar, footer: MainFooter},
    //   props: {header: {colorOnScroll: 450}}
    // },
    // {
    //   path: "/blog-post",
    //   name: "blog-post",
    //   components: {default: BlogPost, header: MainNavbar, footer: MainFooter},
    //   props: {header: {colorOnScroll: 450}}
    // },
    // {
    //   path: "/blog-posts",
    //   name: "blog-posts",
    //   components: {default: BlogPosts, header: MainNavbar},
    //   props: {header: {colorOnScroll: 450}}
    // },
    // {
    //   path: "/ecommerce",
    //   name: "ecommerce",
    //   components: {default: EcommercePage, header: MainNavbar, footer: MainFooter},
    //   props: {header: {colorOnScroll: 450}}
    // },
    // {
    //   path: "/product",
    //   name: "product",
    //   components: {default: ProductPage, header: MainNavbar},
    //   props: {header: {colorOnScroll: 450}}
    // },
    // {
    //   path: "/profile",
    //   name: "profile",
    //   components: {default: ProfilePage, header: MainNavbar, footer: MainFooter},
    //   props: {header: {colorOnScroll: 450}}
    // },
    // {
    //   path: "/sections",
    //   name: "sections",
    //   components: {default: Sections, header: MainNavbar, footer: null},
    //   props: {
    //     footer: {backgroundColor: 'gray'},
    //     header: {colorOnScroll: 0}
    //   }
    // },

    // DASHBOARD VIEWS
    {
      path: "/dashboard-staff",
      name: "dashboard-staff",
      components: {default: DashboardStaff, header: LogStaffNavBar, footer: null},
      props: {footer: {type: 'default'},header: {colorOnScroll: 65}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/staff-management",
      name: "staff-management",
      components: {default: Administrators, header: LogStaffNavBar, footer: null},
      props: {footer: {type: 'default'},header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/profile",
      name: "profile",
      components: {default: Profile, header: LogStaffNavBar, footer: null},
      props: {footer: {type: 'default'},header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/settings",
      name: "settings",
      components: {default: Settings, header: LogExternalUserBar, footer: null},
      props: {footer: {type: 'default'},header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/ecosystems",
      name: "ecosystems",
      components: {default: Ecosystems, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/ecosystems/:id",
      name: "ecosystems-edit",
      components: {default: EcosystemEdit, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/ecosystems/languages/:id",
      name: "ecosystems-language-edit",
      components: {default: EcosystemLanguageEdit, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/ecosystem-services",
      name: "ecosystem-services",
      components: {default: EcosystemServices, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/services-types",
      name: "services-types",
      components: {default: EcosystemsServicesTypes, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/services-categories",
      name: "services-categories",
      components: {default: EcosystemsServicesCategories, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/ecosystem-types",
      name: "ecosystem-types",
      components: {default: EcosystemTypes, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/ecosystem-categories",
      name: "ecosystem-categories",
      components: {default: EcosystemCategories, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/ecosystem-services/languages/:id",
      name: "ecosystem-services-language-edit",
      components: {default: EcosystemServiceLanguageEdit, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/parameters",
      name: "parameters",
      components: {default: Parameters, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/parameters/languages/:id",
      name: "parameters-language-edit",
      components: {default: ParameterLanguageEdit, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/languages",
      name: "languages",
      components: {default: Languages, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/posts",
      name: "posts",
      components: {default: Posts, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/posts/edit/:id",
      name: "posts-edit",
      components: {default: PostsEdit, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/posts/add-post",
      name: "add-post",
      components: {default: AddPost, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/posts/categories",
      name: "post-categories",
      components: {default: CategoriesPosts, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    // {
    //   path: "/posts/multimedia",
    //   name: "posts-multimedia",
    //   components: {default: PostsMultimedia, header: LogStaffNavBar, footer: null},
    //   props: {header: {colorOnScroll: 450}},
    //   beforeEnter : guardMyrouteLoginStaff
    // },
    {
      path: "/tutorials/categories",
      name: "tutorials-categories",
      components: {default: CategoriesTutorials, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/tutorials",
      name: "tutorials",
      components: {default: Tutorials, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/tutorials/edit/:id",
      name: "tutorials-edit",
      components: {default: TutorialEdit, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/tutorials/add-tutorial",
      name: "add-tutorial",
      components: {default: AddTutorial, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    // {
    //   path: "/tutorials/multimedia",
    //   name: "tutorials-multimedia",
    //   components: {default: TutorialsMultimedia, header: LogStaffNavBar, footer: null},
    //   props: {header: {colorOnScroll: 450}},
    //   beforeEnter : guardMyrouteLoginStaff
    // },
    {
      path: "/multimedia",
      name: "multimedia",
      components: {default: Multimedia, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/external-organizations",
      name: "external-organizations",
      components: {default: ExternalOrganizations, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/external-organizations-transfers",
      name: "external-organizations-transfers",
      components: {default: ExternalOrganizationsTransfers, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/external-users",
      name: "external-users",
      components: {default: ExternalUsers, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/settings-cookies",
      name: "settings-cookies",
      components: {default: SettingsCookies, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/settings-emails",
      name: "settings-emails",
      components: {default: SettingsEmails, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/staff-layers",
      name: "staff-layers",
      components: {default: StaffLayers, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    // EXTERNAL USERS VIEW
    {
      path: "/home-user",
      name: "home-user",
      components: {default: HomeUser, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/users-management",
      name: "users-management",
      components: {default: UsersManagement, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/projects-management",
      name: "projects-management",
      components: {default: ProjectsManagement, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    // {
    //   path: "/projects-management/add-project",
    //   name: "add-project",
    //   components: {default: AddProject, header: LogExternalUserBar, footer: null},
    //   props: {header: {colorOnScroll: 450}},
    //   beforeEnter : guardMyrouteLoginStaff
    // },
    {
      
      path: "/user-history",
      name: "user-history",
      components: {default: UserHistory, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      
      path: "/wallet",
      name: "wallet",
      components: {default: Wallet, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      
      path: "/organization-settings",
      name: "organization-settings",
      components: {default: OrganizationSettings, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/maps",
      name: "maps",
      components: {default: Maps, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/ncm-layers",
      name: "ncm-layers",
      components: {default: ExternalLayers, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/invitations",
      name: "invitations",
      components: {default: Invitations, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path:"/view-organization",
      name: "view-organization",
      components: {default: ViewOrg, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/admin-panel-cadastre",
      name: "admin-panel-cadastre",
      components: {default: AdminPanelCadastre, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    // {
    //   path: "/users-management-pm",
    //   name: "users-management-pm",
    //   components: {default: UsersManagementPM, header: LogExternalUserBar, footer: null},
    //   props: {header: {colorOnScroll: 450}},
    //   beforeEnter : guardMyrouteLoginStaff 
    // },
    {
      path: "/admin-panel-data-layers",
      name: "admin-panel-data-layers",
      components: {default: AdminPanelDataLayers, header: LogStaffNavBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    {
      path: "/reports",
      name: "reports",
      components: {default: Reports, header: LogExternalUserBar, footer: null},
      props: {header: {colorOnScroll: 450}},
      beforeEnter : guardMyrouteLoginStaff
    },
    // {
    //   path: "/pdf-design",
    //   name: "pdf-design",
    //   components: {default: PDFDesign, header: LogExternalUserBar, footer: null},
    //   props: {header: {colorOnScroll: 450}},
    //   beforeEnter : guardMyrouteLoginStaff
    // },
    {
      path: "*",
      components: {default: NotFound, header: NavBarLogo}
    },
    

  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  
});

function guardMyrouteLoginStaff(to, from, next){
  var isAuthenticated = false;
  var name_role = ''
  console.log(' path=======', to.path)
  console.log('Guard routes')
  if (localStorage.getItem('cookies_accept')) {
    if (localStorage.getItem('user'))
      isAuthenticated = true;
    else
      isAuthenticated = false;
    if (isAuthenticated) 
    {
      const user = JSON.parse(localStorage.getItem('user'))
      const role = user.data.role
      
      if (user.noStaff){
        if (user.admin) name_role =  'Admin'
      else {
        var pm_roles = user.user_history.filter(f => f.finish_date == null && f.user_role == 'project_manager')
        if (pm_roles.length > 0) name_role =  'Project manager'
        else {
          var analyst_roles = user.user_history.filter(f => f.finish_date == null && f.user_role == 'analyst')
          if (analyst_roles.length > 0) name_role = 'Analyst'
          else name_role = 'No role'
        }
      }
      if (to.path === 'user-history' && name_role != 'Admin') next('/home-user') 
        // Guardar rutas de staff
      
          if (to.path === "/login" || to.path === "/staff" || to.path === "/dashboard-staff" || to.path === "/staff-management" || to.path === "/profile" || to.path === "/ecosystems"
          || to.path.includes("/ecosystems/") || to.path === "/ecosystem-services"|| to.path.includes("/ecosystem-services/") || to.path === "/services-types"|| to.path === "/ecosystem-categories"
          || to.path === "/services-categories"|| to.path === "/ecosystem-types"|| to.path === "/parameters"|| to.path.includes("/parameters/")
          || to.path === "/languages"|| to.path === "/posts"|| to.path.includes("/posts/")|| to.path === "/posts/add-post"|| to.path === "/posts/categories"
          || to.path === "/posts/multimedia"|| to.path === "/tutorials/categories"|| to.path === "/tutorials"|| to.path.includes("/tutorials/") || to.path === "/tutorials/add-tutorial"|| to.path === "/tutorials/multimedia"
          || to.path === "/multimedia" || to.path === "/admin-panel-cadastre" || to.path === "/external-organizations" || to.path === "/external-organizations-transfers"
          || to.path === "/external-users" || to.path === "/settings-cookies" || to.path === "/settings-emails" || to.path === "/staff-layers" || to.path === "/admin-panel-data-layers" ){ 
            next('/home-user') 
          }
          if (to.path === "/wallet" || to.path === "/users-management") {
            if (name_role == 'Project manager' || name_role == 'Analyst') {next ('/home-user')}
            else {next ()}
          }
          else { next()}
        } else {
        // Guardar rutas de usuarios externos
          if (to.path === "/staff" || to.path === "/login" || to.path === "/home-user" || to.path === "/users-management" || to.path === "/wallet" 
          || to.path === "/settings" || to.path === "/maps" || to.path === "/projects-management" || to.path === "/user-history"
          || to.path === "/organization-settings" || to.path === "/ncm-layers" || to.path === "/invitations" || to.path === "/view-organization"
          || to.path === "/reports") 
          { next('/dashboard-staff')  } 
          else {
            if (to.path === "/services-types" || to.path === "/languages" || to.path === "/services-categories" || to.path === "/ecosystem-types" || to.path === "/ecosystem-types" || to.path === "/parameters" || to.path === "/ecosystems") {
              if (role.ecosystem_language_module) {next()}
              else {next('/dashboard-staff')}
            } 
            if (to.path === "/tutorials/categories" || to.path === "/posts/categories" || to.path === "/posts" || to.path === "/tutorials" || to.path === "/multimedia") {
              if (role.blog_module) {next()}
              else {next('/dashboard-staff')}
            } 
            if (to.path === '/external-organizations' || to.path === "/external-organizations-transfers") {
              if (role.organization_module){next()}
              else { next('/dashboard-staff')}
            }
            if (to.path === "/settings-cookies" || to.path === "/settings-emails") {
              if (role.settings_module) {next()}
              else { next('/dashboard-staff')}
            }
            if (to.path === "/admin-panel-cadastre"){
              if (role.organization_module) {next()}
              else {next('/dashboard-staff')}
            }
            if (!(to.path === "/services-types" || to.path === "/services-categories" || to.path === "/ecosystem-types" || to.path === "/ecosystem-types" || to.path === "/parameters" || to.path === "/ecosystems" || to.path === "/tutorials/categories" || to.path === "/posts/categories" || to.path === "/posts" || to.path === "/tutorials" || to.path === "/multimedia" || to.path === "/settings-cookies" || to.path === "/settings-emails")) next ()
        } 
      }
    } 
    else
    { 
      if (to.path === "/staff" || to.path === "/login") next() 
      else next('/login')
    }
  }
  else  {
    if (to.path !== "/") next('/')
  }
}


function guardMyrouteLogin(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem('cookies_accept')) {
    if (localStorage.getItem('user'))
      isAuthenticated = true;
    else
      isAuthenticated = false;
    if (isAuthenticated) {
      next();
    } else {
      if (to.path === "/posts-landing" || to.path === "/post") {
        next('/explore');
      } else {
        next();
      }
    }
  } else {
    if (to.path !== "/") next('/')
  }
}

// //Improved guardMyrouteLoginStaff
// function guardMyrouteLoginStaff(to, from, next) {
//   if (!localStorage.getItem('cookies_accept')) {
//     return to.path !== '/' ? next('/') : next();
//   }

//   const user = localStorage.getItem('user');
//   if (!user) {
//     return to.path === '/staff' || to.path === '/explore' || to.path === '/login' ? next() : next('/login');
//   }

//   const parsedUser = JSON.parse(user);
//   const role = parsedUser.data.role;

//   let name_role = 'No role';
//   if (parsedUser.noStaff) {
//     if (parsedUser.admin) {
//       name_role = 'Admin';
//     } else {
//       const pm_roles = parsedUser.user_history.filter(f => f.finish_date == null && f.user_role === 'project_manager');
//       if (pm_roles.length > 0) {
//         name_role = 'Project manager';
//       } else {
//         const analyst_roles = parsedUser.user_history.filter(f => f.finish_date == null && f.user_role === 'analyst');
//         if (analyst_roles.length > 0) {
//           name_role = 'Analyst';
//         }
//       }
//     }
//   }

//   const staffRoutes = [
//     "/login", "/staff", "/dashboard-staff", "/staff-management", "/profile", "/ecosytems",
//     "/ecosystems/:id", "/ecosystems/languages/:id", "/ecosystem-services", "/services-types",
//     "/ecosystem-categories", "/services-categories", "/ecosystem-types", "/ecosystem-services/languages/:id",
//     "/parameters", "/parameters/languages/:id", "/languages", "/posts", "/posts/edit/:id", "/posts/add-post",
//     "/posts/categories", "/posts/multimedia", "/tutorials/categories", "/tutorials", "/tutorials/edit/:id",
//     "/tutorials/add-tutorial", "/tutorials/multimedia", "/multimedia", "/admin-panel-cadastre"
//   ];

//   const restrictedRoutesForPMAnalyst = ["/wallet", "/users-management"];
//   const externalUserRoutes = [
//     "/staff", "/login", "/home-user", "/users-management", "/wallet", "/settings", "/maps"
//   ];

//   if (parsedUser.noStaff) {
//     if (staffRoutes.includes(to.path)) {
//       return next('/home-user');
//     }
//     if (restrictedRoutesForPMAnalyst.includes(to.path) && (name_role === 'Project manager' || name_role === 'Analyst')) {
//       return next('/home-user');
//     }
//     return next();
//   }

//   if (externalUserRoutes.includes(to.path)) {
//     return next('/dashboard-staff');
//   }

//   const moduleRoutes = [
//     { paths: ["/services-types", "/languages", "/services-categories", "/ecosystem-types", "/parameters", "/ecosystems"], module: "ecosystem_language_module" },
//     { paths: ["/tutorials/categories", "/posts/categories", "/posts", "/tutorials", "/multimedia"], module: "blog_module" },
//     { paths: ["/external-organizations", "/external-organizations-transfers"], module: "organization_module" },
//     { paths: ["/settings-cookies", "/settings-emails"], module: "settings_module" },
//     { paths: ["/admin-panel-cadastre"], module: "organization_module" }
//   ];

//   for (let { paths, module } of moduleRoutes) {
//     if (paths.includes(to.path)) {
//       return role[module] ? next() : next('/dashboard-staff');
//     }
//   }
//   return next();
// }

// // Improved guardMyrouteLogin
// function guardMyrouteLogin(to, from, next) {
//   const cookiesAccepted = localStorage.getItem('cookies_accept');
//   const userAuthenticated = localStorage.getItem('user');

//   if (!cookiesAccepted) {
//     // Redirect to home if cookies are not accepted and trying to access any route other than home
//     if (to.path !== "/") {
//       next('/');
//     } else {
//       next();
//     }
//   } else if (userAuthenticated) {
//     // Allow navigation if user is authenticated
//     next();
//   } else {
//     // Redirect to explore page if trying to access posts-related routes
//     if (to.path === "/posts-landing" || to.path === "/post") {
//       next('/explore');
//     } else {
//       // Allow navigation to other routes (like login, register, etc.)
//       next();
//     }
//   }
// }

// function guardMyrouteLogin(to, from, next) {
//   var isAuthenticated = false;
//   if (localStorage.getItem('cookies_accept')) {
//     if (localStorage.getItem('user')) {
//       isAuthenticated = true;
//     } else {
//       isAuthenticated = false;
//     }
//     if (isAuthenticated) {
//       var user = JSON.parse(localStorage.getItem('user'));
//       if (name_role === 'Analyst' && (to.path === "/users-management" || to.path === "/user-history" || to.path ==="/invitations" || to.path === "/organization-settings")) {
//         // Si el usuario es Analista y está intentando acceder a vistas restringidas, redirige a otra página
//         next('/home-user');
//       } else {
//         next();
//       }
//     } else {
//       if (to.path === "/posts-landing" || to.path === "/post") {
//         next('/explore');
//       } else {
//         next();
//       }
//     }
//   } else {
//     if (to.path !== "/") next('/');
//   }
// }


export default router;