<template>
    <div class="wrapper  dashboard-wrapper collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{$t('SettingsEmail.Title')}}
                    </h2>
                </b-col>
                <b-col class="buttonSave">
                    <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right">
                        <b-button-group class="mx-1">
                            <n-button
                            class="btn-simple ncm-btn"
                            style="font-weight: 800 !important; float: right;"
                            target="_blank"
                            @click.prevent="update()"
                            >
                            <b-icon icon="cloud-arrow-up-fill" class="button-icon" ></b-icon>
                            {{$t('CookiesSettings.Save')}}
                            </n-button>
                        </b-button-group>
                    </b-button-toolbar>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3" style="display: flex" class="languageDropDown">
                    <drop-down hidden>
                    <n-button style="margin-top: 2%; "
                        slot="title"
                        class="dropdown-toggle"
                        type="primary"
                        data-toggle="dropdown"
                    >

                    <!-- <template v-if="tpSelected == undefined">
                        Without message type
                    </template>
                    <template v-else>
                        {{ tpSelected.name }}
                    </template> -->
                    <template v-if="languageSelected == undefined">
                        {{$t('SettingsEmail.Error')}}
                        </template>
                    <template v-else>
                        {{ languageSelected.name }}
                    </template>
                </n-button>
                    <a v-for="(lg, index) of languages"
                    class="dropdown-item" :key="index" style="cursor: default;" @click="filterLg(lg)" :class="{ 'active': lg.name === languageSelected.name } "
                    >{{lg.name}}</a>
                        <!-- <a v-for="(tp, index) of types"
                        class="dropdown-item" :key="index" style="cursor: default;" @click="filterLg(lg)" :class="{ 'active': tp.name === tpSelected.name }"
                        >{{tp.name}}</a> -->
                    </drop-down>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('SettingsEmail.Activate')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseActivateAccount" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedActivateAccount" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedActivateAccount" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedActivateAccount">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="msgActivate.content"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('SettingsEmail.InviteExistingUser')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseInvitationExistingUser" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedInvitationExistingUser" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedInvitationExistingUser" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedInvitationExistingUser">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="msgInvitationExisting.content"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                </b-row>
                <b-row>
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('SettingsEmail.InviteNewUser')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseInvitationNewUser" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedInvitationNewUser" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedInvitationNewUser" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedInvitationNewUser">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="msgInvitationNewUser.content"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('SettingsEmail.ResetPwd')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseResetPassword" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedResetPassword" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedResetPassword" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedResetPassword">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="msgResetPassword.content"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                </b-row>
                <b-row>
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('SettingsEmail.Contact')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseContact" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedContact" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedContact" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedContact">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="msgContact.content"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('SettingsEmail.InviteAnalyst')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseInviteAnalyst" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedInviteAnalyst" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedInviteAnalyst" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedInviteAnalyst">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="msgInviteAnalyst.content"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                </b-row>
            <!-- <b-row class="mt-3">
                <b-col>
                    <card>
                        <b-row>
                            <b-col cols="5"  style="float: left;">
                                <h4 class="m-auto" style="font-weight: 600; margin-top: 5px  !important;">Tipo de mensaje: </h4>
                            </b-col>
                            <b-col>
                                <b-button variant="link" @click="toggleCollapse" class="m-auto" style="font-size: 15px; float: right">
                                    <b-icon style="color: #5D6176 ;" v-if="!isCollapsed" icon="chevron-double-down"></b-icon>
                                    <b-icon style="color: #5D6176 ;" v-if="isCollapsed" icon="chevron-double-up"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-collapse v-model="isCollapsed">
                            <b-row>
                                <b-col>
                                <vue-editor
                                    class="mt-3"
                                    ref="vue-editor"
                                    :editor-toolbar="toolbarOptions"
                                />
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </card>
                </b-col>
            </b-row>     -->
        </b-container>
        <modal :show.sync="modals.exit" class="modal-primary" :show-close="false" header-classes="justify-content-center">
            <p>{{$t("EcosystemEdit.ExitViewMessage")}}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="exitWithoutChanges()">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click="modals.exit = false">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
    </div>
</template>
<script>
import { Button, FormGroupInput, Card, Modal, DropDown} from '@/components';
import {Table, TableColumn, Tooltip} from 'element-ui';
import { VueEditor } from "vue2-editor";
import SettingsService from '../../services/SettingsDataService';
import SettingsMailDataService from "../../services/SettingsMailDataService";
import LanguageDataService from '../../services/LanguageDataService';
export default {
    components: {
      Modal,
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
      [DropDown.name]: DropDown,
      VueEditor
    },
    data: () => ({
        modals: {
            exit: false
        },
        // isCollapsed: false,
        isCollapsedActivateAccount: false,
        isCollapsedInvitationExistingUser: false,
        isCollapsedInvitationNewUser: false,
        isCollapsedResetPassword: false,
        isCollapsedContact: false,
        isCollapsedInviteAnalyst: false,
        editing: false,
        toolbarOptions: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["link"],

    ],
    view_to: '',
    allMsgs:[],
    types:[],
    type: null,
    languages:[],
    language: null,
    languageSelected: null,
    msgActivate: null,
    msgInvitationExisting: null,
    msgInvitationNewUser: null,
    msgResetPassword: null,
    msgContact: null,
    msgInviteAnalyst: null,
    // Delete this when the client gives the templates for each mail
    // tpSelected:
    // {
    //     activate_account: "This is a message to activate your account, click the link below to activate it.",
    //     invitation_existing_user: "You were invited to a new org, if you are already in this org, ignore this mail.",
    //     invitation_new_user: "You were invited to a new org, click on the link below to enter.",
    //     reset_password: "Someone is trying to reset your password, if you asked for it, click on the link below to change it, if not, ignore this message.",
    //     contact: "If you want to contact us to ask any questions, send a ticket and we will try to answer as fast as we can.",
    // },
    }),
    beforeRouteLeave(to, from, next) {
        this.view_to = to

        if (this.editing)
            this.modals.exit = true
        else
            next()
    },
    created() {
        this.getData()
    },
    computed: {
        tpSelected () {
            const tpSelected = this.allMsgs;
            return tpSelected || {};
        }
    },
    methods: {
        startEditing(){
            console.log('editing')
            this.editing = true
        },
        toggleCollapseActivateAccount() {
            this.isCollapsedActivateAccount = !this.isCollapsedActivateAccount;
        },
        toggleCollapseInvitationExistingUser() {
            this.isCollapsedInvitationExistingUser = !this.isCollapsedInvitationExistingUser;
        },
        toggleCollapseInvitationNewUser() {
            this.isCollapsedInvitationNewUser = !this.isCollapsedInvitationNewUser;
        },
        toggleCollapseResetPassword() {
            this.isCollapsedResetPassword = !this.isCollapsedResetPassword;
        },
        toggleCollapseContact() {
            this.isCollapsedContact = !this.isCollapsedContact;
        },
        toggleCollapseInviteAnalyst() {
            this.isCollapsedInviteAnalyst= !this.isCollapsedInviteAnalyst;
        },
        filterLg(lg) {
            this.language = lg.id
            this.languageSelected = lg
        },
        exitWithoutChanges(){
            this.editing = false
            this.modals.exit=false
            this.$router.push(this.view_to)
        },
        async getData() {
            try {
                const languageResponse = await LanguageDataService.findAll()
                this.languages = languageResponse.data;
                if (this.language == null) {
                    let lg = this.languages.filter(f => f.name.toUpperCase() == 'ENGLISH')[0]
                    this.language = lg.id;
                    this.languageSelected = lg
                }
                await SettingsMailDataService.findAll().then(
                    Response=> {
                        this.allMsgs = Response.data.data
                        this.msgInviteAnalyst = this.allMsgs.find(message => message.type === 'invitation-as')
                        this.msgContact = this.allMsgs.find(message => message.type === 'contact')
                        this.msgInvitationExisting = this.allMsgs.find(message => message.type === 'invitation-existing-user')
                        this.msgResetPassword = this.allMsgs.find(message => message.type === 'reset-password')
                        this.msgInvitationNewUser = this.allMsgs.find(message => message.type === 'invitation-new-user')
                        this.msgActivate = this.allMsgs.find(message => message.type === 'activate-account')
                        },
                        Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                        }
                )
            } catch (error) {
                console.log('Error en el findAll de los Msgs:', error)
            }
        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
            }
            return null
        },
        update () {
            var updateOk = true;
            // Llamada al endpoint actualizar el tipo de mensaje de correo electrónico
                for (var i of this.tpSelected) {
                    if(updateOk==true){
                    SettingsMailDataService.update(i).then(
                        Response => {
                            console.log("Cambiado correctamente", updateOk)
                        },
                        Error => {
                            updateOk = false;
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    )
                }
            }
            if(updateOk == true){
                this.$swal.fire({
                    icon: 'success',
                    title: this.$t('SettingsEmail.Edit')
                    }).then(() => {
                        this.getData()
                        this.editing = false
                        this.isCollapsedInvitationExistingUser = false
                        this.isCollapsedInvitationNewUser = false
                        this.isCollapsedContact = false
                        this.isCollapsedActivateAccount = false
                        this.isCollapsedResetPassword = false
                        this.isCollapsedInviteAnalyst = false
                    }); 
            }

        },
        create () {
            // Llamada al endpoint crear el tipo de mensaje la primera vez que creamos en la base de datos
            SettingsMailDataService.create(this.tpSelected).then(
                Response => {
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('SettingsEmail.Created')
                    }).then(() => {
                        this.getData()
                        this.editing = false
                        this.isCollapsedInvitationExistingUser = false
                        this.isCollapsedInvitationNewUser = false
                        this.isCollapsedContact = false
                        this.isCollapsedActivateAccount = false
                        this.isCollapsedResetPassword = false
                        this.isCollapsedInviteAnalyst = false
                    });
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        }

    }
}
</script>
<style>

@media (max-width: 416px) {
    .languageDropDown {
        margin-left: 70px;

    }
    .buttonSave{
        display: flex;
        justify-content: center;
    }
}

</style>