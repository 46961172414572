<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <template v-if="user_role">
            <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <template v-if="user_role == 'admin'">
                    <b-row>
                        <b-col>
                            <div class="small-box admin-small-box bg-success" @click="$router.push({ path: '/users-management' })" style="cursor: pointer !important">
                                <div class="inner">
                                    <h3 v-if="invitations" class="increase-count">{{invitations}}</h3>
                                    <h3 v-else class="increase-count"> ---</h3>
                                    <a >
                                        <p>{{$t('HomeUser.Invitations')}}</p>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a >
                                        <i class="fa fa-envelope"/>
                                    </a>
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="small-box admin-small-box bg-success" @click="$router.push({ path: '/users-management' })" style="cursor: pointer !important">
                                <div class="inner">
                                    <h3 v-if="members" class="increase-count">{{members}}</h3>
                                    <h3 v-else class="increase-count">{{members}}</h3>
                                    <a >
                                        <p>{{$t('HomeUser.Users')}}</p>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a >
                                        <i class="fa fa-users" />
                                    </a>
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="small-box admin-small-box bg-success" @click="$router.push({ path: '/reports' })" style="cursor: pointer !important">
                                <div class="inner">
                                    <h3 v-if="reports" class="increase-count">{{ reports }}</h3>
                                    <h3 v-else class="increase-count">---</h3>
                                    <a >
                                        <p>{{$t('HomeUser.Reports')}}</p>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a >
                                        <i class="fa fa-file" />
                                    </a>
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="small-box admin-small-box bg-success"  @click="$router.push({ path: '/wallet' })" style="cursor: pointer !important">
                                <div class="inner">
                                    <h3 v-if="wallet" class="increase-count"><b-icon icon="coin"></b-icon>{{wallet.toFixed(2)}}</h3>
                                    <a >
                                        <p>{{$t('HomeUser.Wallet')}}</p>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a >
                                        <i class="fa fa-credit-card" />
                                    </a>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
            </template>
            <template v-else>
                    <!-- <b-row class="page-header-bs">
                        <b-col style="display: flex">
                        <h2 class="card-title">
                            <b-icon icon="house-door"/>  {{$t('HomeUser.Welcome')}}:  {{ user.data.name + ' ' + user.data.surname + '(Analyst)'}}
                        </h2>
                        </b-col>
                    </b-row> -->
                    <b-row>
                        <card class="cardInfo" style="width: 40%; margin-left: 30%;">
                            <b-row style="margin-bottom: 4.7%">
                                <template>
                                    <b-col>
                                        <b-form-group label="Info:">
                                            <span>{{$t('HomeUser.Text')}}</span>
                                        </b-form-group>
                                    </b-col>
                                </template>
                            </b-row>
                            <b-row style="float: center; margin-left: 50%; margin-top: -40px">
                                <n-button class="btn-simple ncm-btn" style="margin-left: -60px;" id="sendBtn" @click="openModal()">
                                    {{$t('UsersManagement.Modals.Invite.Send')}} 
                                </n-button>
                            </b-row>
                        </card>
                    </b-row>
            </template>
            <b-row v-if="user_role == 'admin' || user_role == 'project_manager'">
                    <card class="cardTable" style="width: 100%;">
                        <b-row style="margin-bottom: 4.7%">
                            <template>
                                <b-col>
                                    <b-table
                                    ref="tokensTable"
                                    id="tokensTable"
                                    :fields="info_headers" 
                                    :items="requests"
                                    responsive="sm"
                                    show-empty
                                    > 
                                        <template #empty="">
                                            {{ $t('HomeUser.NoRequests') }}
                                        </template>

                                        <template #cell(createdAt)="data">
                                            {{ data.item.createdAt | formatDate }}
                                        </template>

                                        <template #cell(options)="data">
                                            <b-button class="action-table" v-b-tooltip.hover.viewport title="Accept tokens request" @click="openRequestAcceptModal(data.item)">
                                                <b-icon icon="check2"></b-icon>
                                            </b-button>
                                            <b-button class="action-table" v-b-tooltip.hover.viewport title="Cancel tokens request" @click="openRequestCancelModal(data.item)">
                                                <b-icon icon="x-circle"></b-icon>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <div class="pagination-container">
                                        <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                        </b-pagination>
                                        <b-form-select v-model="perPage" class="pagination-select">
                                            <b-form-select-option :value="5">5</b-form-select-option>
                                            <b-form-select-option :value="10">10</b-form-select-option>
                                            <b-form-select-option :value="20">20</b-form-select-option>
                                            <b-form-select-option
                                                :value="requests.length">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                        </b-form-select>
                                    </div>
                                </b-col>
                            </template>
                        </b-row>
                    </card>
                </b-row>
            </b-container>
            
        </template>

        <modal :show.sync="modals.request" :show-close="false" header-classes="justify-content-center" >
            <h4 slot="header" class="title title-up">
                {{$t('HomeUser.AskTokens')}}
            </h4>

            <b-form-group>
                {{$t('HomeUser.SelectProject')}}:
                <b-form-select
                v-model="selectedProject"
                required
                @change="onProjectChange"
                >
                <!-- <option :value="null" disabled></option> -->
                <template v-for="(project, i) in $currentProjects">
                    <option v-if="project != null" :key="i" :value="project"> {{ project.name }}</option>
                </template>
                <!-- <template #first>
                    <option :value="null">
                    {{ selectedProject ? selectedProject.name : $projectSelected.name }}
                    </option>
                </template> -->
                </b-form-select>
            </b-form-group>
            <b-form-group>
                {{$t('HomeUser.AmountTokens')}}:
                <b-form-input
                    v-model="tokenAmount"
                    type="number"
                    min="1"
                    required
                />
            </b-form-group>
            <template slot="footer">
                
                <n-button class="btn-simple ncm-btn" @click="createTokenRequest()">
                {{ $t("Languages.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger" @click="modals.request = false">
                {{ $t("Languages.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>

        <modal :show.sync="modals.accept" :show-close="false" header-classes="justify-content-center" >
            <h4 slot="header" class="title title-up">
                {{ $t('HomeUser.AcceptRequest') }}
            </h4>
            <label v-if="selectedElement">{{ $t('HomeUser.AcceptTransfer') }}<em><b>{{selectedElement.tokens}} tokens</b></em>{{ $t('HomeUser.For') }}<em><b>{{ selectedElement.project.name }}</b></em></label>
            <template slot="footer">
                
                <n-button class="btn-simple ncm-btn" @click="acceptTokens()">
                {{ $t("Languages.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger" @click="modals.accept = false">
                {{ $t("Languages.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>

        <modal :show.sync="modals.cancel" :show-close="false" header-classes="justify-content-center" >
            <h4 slot="header" class="title title-up">
                {{ $t('HomeUser.CancelRequest') }}
            </h4>
            <label v-if="selectedElement">{{ $t('HomeUser.CancelTransfer') }}<em><b>{{selectedElement.tokens}} tokens</b></em>{{ $t('HomeUser.For') }}<em><b>{{ selectedElement.project.name }}</b></em></label>
            <template slot="footer"> 
                <n-button class="btn-simple ncm-btn" @click="cancelTokens()">
                {{ $t("Languages.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger" @click="modals.cancel = false">
                {{ $t("Languages.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
    </div>
</template>
<script>
  const $ = require('jquery')
  import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  import { CONNECTING } from 'ws';
  import WalletDataServices from '../../services/WalletDataServices';
  import TokenRequestsDataService from '../../services/TokenRequestsDataService';

import OrganizationsDataService from '../../services/OrganizationsDataService';
import ProjectsDataService from '../../services/ProjectsDataService';
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });
export default {
    name: 'ProfilePage',
    bodyClass: 'profile-page',
    components: {
        Modal,
        Card,
        [Checkbox.name]: Checkbox,
        [DropDown.name]: DropDown,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
    },
    watch : {
        $projectSelected(newValue){
            console.log('Project selected changed!', newValue)
            this.projectSelected = newValue
        },
        $roleSelected(newValue){
            console.log('Org selected changed!', newValue)
            this.user_role = newValue.user_role
            this.getData()
        },
        $tokensValue(newValue){
            this.getData()
        },
    },
    created () {
        this.user = this.$store.state.auth.user
        this.user_role = this.$roleSelected.user_role
        this.getData()


    },
    data() {
        return {
            modals: {
                request: false,
                accept: false,
                cancel: false
            },
            orgSelected: null,
            projects: [],
            projectSelected: null,
            org_id: null,
            wallet: undefined,
            invitations: undefined,
            members:  undefined,
            emptyWallet: false,
            user: undefined,
            user_role: undefined,
            selectedElement: null,
            selectedProject: null,
            info_headers: [
                { key: 'user.email', label: this.$t('HomeUser.Email'), sortable: true},
                { key: 'project.name', label: this.$t('HomeUser.Project'), sortable: true},
                { key: 'tokens', label: this.$t('HomeUser.Tokens'), sortable: true},
                { key: 'options', label: this.$t('HomeUser.Actions'), sortable: false},
            ],
            // requests: [
            //     {name: 'Marcos Santos', project: 'Bisite', tokens: 100},
            //     {name: 'Victor Ramos', project: 'Project 2', tokens: 200},
            //     {name: 'Marc S', project: 'Proyecto Prueba', tokens: 100},
            //     {name: 'Victor Analista', project: 'Bisite 2', tokens: 200},
            // ],
            currentPage: 1,
            perPage: 10,
            requests: [],
            reports: undefined
        }
    },
    computed: {
        totalPages () {
            return this.requests.length
        },
        filteredProjectOptions() {
            return this.projects.filter(project => project.id !== this.selectedProject?.id)
        }
    },
    methods: {
        acceptTokens(){
            let request_tokens = this.selectedElement.tokens

            if(this.user_role == 'admin'){
                console.log('tokens available', this.$tokensValue)
                if(this.$tokensValue < request_tokens){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: this.$t('Alerts.ErrorNoTokensToTransfer')
                    })
                    return
                }
                else{
                    TokenRequestsDataService.setRequestStatus(true, this.selectedElement.id).then(
                        Response => {
                            this.modals.accept = false
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Token request acepted!',
                            })
                            let new_tokens = parseFloat(this.$tokensValue) - request_tokens
                            if(new_tokens > 0)
                                this.$tokensValue = new_tokens.toFixed(2)
                            else
                                this.$tokensValue = '0.00'

                            this.getData()
                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    )
                    
                }
            }
            else if(this.user_role == 'project_manager'){
                let project_request = this.$currentProjects.filter(f => f.id == this.selectedElement.project_id)[0]

                console.log('tokens available', project_request.tokens_available)
                if(project_request.tokens_available < request_tokens){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: this.$t('Alerts.ErrorNoTokensToTransfer')
                    })
                    return
                }
                else{
                    TokenRequestsDataService.setRequestStatus(true, this.selectedElement.id).then(
                        Response => {
                            this.modals.accept = false
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Token request acepted!',
                            })
                            let new_tokens = parseFloat(project_request.tokens_available) - request_tokens
                            if(new_tokens > 0){
                                project_request.tokens_available = new_tokens.toFixed(2)
                                this.$tokensValue = new_tokens.toFixed(2)
                            }  
                            else{
                                project_request.tokens_available = '0.00'
                                this.$tokensValue = '0.00'
                            }
                                

                            this.getData()
                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    )
                    
                }
            }
        },
        cancelTokens(){
            TokenRequestsDataService.setRequestStatus(false, this.selectedElement.id).then(
                        Response => {
                            this.modals.cancel = false
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Token request canceled!',
                            })
                            this.getData()
                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    )
        },
        openRequestAcceptModal(request){

            this.selectedElement = request
            this.modals.accept = true
        },
        openRequestCancelModal(request){

        this.selectedElement = request
        this.modals.cancel = true
        },
        openModal(){
            this.modals.request = true
            this.selectedProject = this.$projectSelected
        },
        createTokenRequest(){

            let data = {
                organization_id: this.$orgSelected.id,
                project_id: this.selectedProject.id,
                tokens: this.tokenAmount,
                level: this.$roleSelected.user_role
            }
            if (this.tokenAmount >= 1) {
                TokenRequestsDataService.create(data).then(
                    Response => {
                        this.modals.request = false
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Token request created!',
                        })
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
                )
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.$t('Alerts.ErrorMustBeGreaterThanOne')
                })
            }
        },
        getData() {
            if(this.user_role == 'admin'){
                console.log('org home user', this.$orgSelected)
                if (this.$orgSelected == null){
                    return
                }
                this.org_id = this.$orgSelected.id
                if (this.org_id != null) {
                    OrganizationsDataService.findAllDataCards(this.org_id).then(
                        Response => {
                            console.log(Response.data)
                            this.invitations = Response.data.invitations.length
                            this.members = Response.data.users.length
                            this.wallet = Response.data.balance_org
                            this.reports = Response.data.reports.length
                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    )
                    // OrganizationsDataService.findByOrganizationId(this.org_id).then(
                    //     Response => {
                    //         this.members = Response.data.data.length
                    //     },
                    //     Error => {
                    //         this.$swal.fire({
                    //             icon: 'error',
                    //             title: 'Error',
                    //             text: Error.response.data.message
                    //         })
                    //     }
                    // )
                    // if (this.org_id == undefined){
                    //     return
                    // }
                    // WalletDataServices.getTokens(this.org_id).then(
                    //     Response => {
                    //         this.wallet = Response.data
                    //         console.log(this.wallet)
                    //     },
                    //     Error => {
                    //         this.$swal.fire({
                    //             icon: 'error',
                    //             title: 'Error',
                    //             text: Error.response.data.message
                    //         })
                    //     }
                    // )
                }
                
            }
            if (this.org_id != null && this.$roleSelected.user_role != 'analyst') {
                TokenRequestsDataService.getRequests(this.org_id, this.$roleSelected.user_role).then(
                        Response => {
                            this.requests = Response.data.data
                            console.log(this.requests)
                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    )

            }
            

        },
        onProjectChange() {
            console.log("Proyecto seleccionado:", this.selectedProject);
            // Lógica adicional cuando se selecciona un proyecto
        },
        chooseProject(project, orgSelected){
        
            this.projectSelected = project
            this.$projectSelected = this.projectSelected
            // this.getTokensValue()
            console.log(this.projectSelected)
        },
        openRequestModal(element){
            console.log(element)
            this.selectedElement = element
            this.modals.request = true
        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        }
    }
}
</script>
<style>

.custom-border-right {
    border-right: 4px solid #5D6176;
}

.admin-small-box:hover {
  /* Add your hover styles here */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media (max-width: 1425px){
    .small-box {
        margin-top: 20px;
    }
}

@media (max-width: 1364px){
    .small-box {
        margin-top: 40px;
    }
}

@media (max-width: 738px){
    .small-box {
        margin-top: 60px;
    }
}

@media (max-width: 300px){
    .small-box {
        /* margin-top: 80px; */
        margin-left: 20px;
    }
}

@media (max-width: 1563px){
    .cardInfo {
        /* margin-top: 80px; */
        margin-top: 20px;
    }
}

@media (max-width: 1142px){
    .cardInfo {
        /* margin-top: 80px; */
        margin-top: 40px;
    }
}

@media (max-width: 700px){
    #sendBtn {
        /* margin-top: 80px; */
        margin-top: 30px;
    }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>